import { PageEvents as on } from "./page_events"
import { Turbo } from "@hotwired/turbo-rails"

export default class Forms {
  static initalised = false

  constructor() {
    if (Forms.initalised) return

    on.event("change", this.onChange.bind(this))
  }

  onChange(event) {
    const target = event.target
    const form = target.closest("form.update-on-change")
    if (!form) return

    Turbo.navigator.submitForm(form)
  }
}

on.domLoaded().then(() => new Forms)

import { PageEvents as on } from "../../shared/page_events"
import throttle from "lodash.throttle"
import { Turbo } from "@hotwired/turbo-rails"

class Search {
  static #singleton

  static initialise() {
    this.#singleton = new Search
  }

  #form
  #input

  constructor() {
    this.#form = document.querySelector("#search-bar form")
    this.#input = document.getElementById("search_query")
    if (!this.#form || !this.#input) return

    this.#addEventListeners()
  }

  #addEventListeners() {
    this.#submitFormOnInput()
    this.#submitFormOnClear()
  }

  #submitFormOnInput() {
    const callback = this.#submitForm.bind(this)
    const throttledSubmit = throttle(callback, 500, { leading: false })
    this.#input.addEventListener("keydown", throttledSubmit)
  }

  #submitFormOnClear() {
    const callback = this.#onClear.bind(this)
    this.#input.addEventListener("search", callback)
  }

  #onClear() {
    if (this.#input.value === "") {
      this.#submitForm()
    }
  }

  #submitForm() {
    Turbo.navigator.submitForm(this.#form)
  }
}

on.event("turbo:load", () => Search.initialise())

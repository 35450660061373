import * as Turbo from '@hotwired/turbo'
Turbo.start()

import "trix"
import "@rails/actiontext"

import "~/javascript/components"
import "~/javascript/controllers"
import "~/javascript/shared/forms"
import "~/javascript/shared/components/search"

import Toggle from "./toggle"
import { PageEvents as on } from "../shared/page_events"

class Toggles {
  constructor() {
    on.event("click", this.onClick.bind(this))
  }

  onClick(event) {
    const target = event.target
    if (!target.classList.contains("toggle-button")) return

    event.preventDefault()
    const toggle = new Toggle(target)
    toggle.onClick()
  }
}
on.domLoaded().then(() => new Toggles)

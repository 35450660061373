import Animation from "../shared/animation"
import debounce from "lodash.debounce"
import { PageEvents as on } from "../shared/page_events"

class Sidebar {
  static #singleton

  static initalise() {
    this.#singleton = new Sidebar
  }

  static onClick(event) {
    if (!this.#singleton) return

    this.#singleton.onClick(event)
  }

  static handleWindowResize() {
    const responsiveSidebarToggle = () => {
      if (this.#singleton) this.#singleton.responsiveSidebarToggle()
    }
    const debounceToggle = debounce(responsiveSidebarToggle, 300)
    window.addEventListener("resize", debounceToggle)
  }

  #sidebar
  #toggleButton
  #animating = false

  constructor() {
    this.#sidebar = document.getElementById("sidebar")
    this.#toggleButton = document.querySelector("#app-header .sidebar-toggle")
  }

  onClick(event) {
    const target = event.target
    if (!this.#toggleButton || !this.#toggleButton.contains(target)) return

    event.preventDefault()
    this.#toggleSidebar()
  }

  responsiveSidebarToggle() {
    if (!this.#sidebar) return

    if (this.#isMobile()) {
      if (this.#isOpen()) this.#close()
    } else {
      if (this.#isClosed()) this.#open()
    }
  }

  #isMobile() {
    return window.innerWidth < 768
  }

  #toggleSidebar() {
    if (this.#isOpen()) {
      this.#close()
    } else {
      this.#open()
    }
  }

  // On page load, md:block is used to show the sidebar on desktop. This is removed when the user
  // toggles the sidebar. This means that, if md:block isn't in the class list, the hidden test will
  // work for both mobile and desktop
  #isOpen() {
    if (!this.#isMobile() && this.#sidebar.classList.contains("md:block")) {
      return true
    } else {
      return !this.#sidebar.classList.contains("hidden")
    }
  }

  #close() {
    if (this.#animating || this.#isClosed()) return

    this.#animating = true

    Animation.fadeAndHide(this.#sidebar).then(() => {
      const openClassList = this.#toggleButton.querySelector("svg.open").classList
      openClassList.add("hidden")
      openClassList.remove("md:block")
      this.#toggleButton.querySelector("svg.closed").classList.remove("hidden", "md:hidden")
      this.#animating = false
    })
  }

  #isClosed() {
    return !this.#isOpen()
  }

  #open() {
    if (this.#animating || this.#isOpen()) return

    this.#animating = true

    Animation.showAndFade(this.#sidebar).then(() => {
      this.#toggleButton.querySelector("svg.open").classList.remove("hidden")
      this.#toggleButton.querySelector("svg.closed").classList.add("hidden")
      this.#animating = false
    })
  }
}

on.event("turbo:load", () => Sidebar.initalise())
on.event("click", (event) => Sidebar.onClick(event))
on.domLoaded().then(() => Sidebar.handleWindowResize())

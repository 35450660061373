export default class Animation {
  static #fadingTransitions = {
    global: ["transition", "transform"],
    in: {
      duration: 100,
      around: ["ease-out", "duration-100"],
      from: ["opacity-0", "scale-95"],
      to: ["opacity-100", "scale-100"],
    },
    out: {
      duration: 75,
      around: ["ease-in", "duration-75"],
      from: ["opacity-100", "scale-100"],
      to: ["opacity-0", "scale-95"],
    }
  }

  static showAndFade(element) {
    this.#applyGlobalFadeClassList(element)

    const transition = this.#fadingTransitions.in
    element.classList.add(...[...transition.around, ...transition.from])

    return new Promise(resolve => {
      setTimeout(
        () => {
          // It is sometimes necessary to use Tailwind classes to hide an element at particular
          // screen sizes before JavaScript is ready. These classes then get in the way when
          // toggling the visibilty of the element with JavaScript
          element.classList.remove("hidden", "md:block")
          element.classList.remove(...transition.from)
          element.classList.add(...transition.to)
        }, 0
      )
      setTimeout(
        () => {
          element.classList.remove(...[...transition.around, ...transition.to])
          resolve()
        }, transition.duration
      )
    })
  }

  static fadeAndHide(element) {
    this.#applyGlobalFadeClassList(element)

    // It is sometimes necessary to use Tailwind classes to hide an element at particular
    // screen sizes before JavaScript is ready. These classes then get in the way when
    // toggling the visibilty of the element with JavaScript
    element.classList.remove("hidden", "md:block")
    const transition = this.#fadingTransitions.out
    element.classList.add(...transition.around, ...transition.from)

    return new Promise(resolve => {
      setTimeout(
        () => {
          element.classList.remove(...transition.from)
          element.classList.add(...transition.to)
        }, 0
      )
      setTimeout(
        () => {
          element.classList.add("hidden")
          element.classList.remove(...transition.around, ...transition.to)
          resolve()
        }, transition.duration
      )
    })
  }

  static #applyGlobalFadeClassList(element) {
    element.classList.add(...this.#fadingTransitions.global)
  }
}

export default class Toggle {
  #hiddenInput
  #toggle

  constructor(toggle) {
    this.#toggle = toggle
    this.#hiddenInput = this.#toggle.querySelector("input[type=hidden]")
  }

  onClick() {
    if (this.#checked()) {
      this.#uncheck()
    } else {
      this.#check()
    }
  }

  #checked() {
    return this.#hiddenInput.value === "Yes"
  }

  #uncheck() {
    this.#toggle.ariaChecked = "false"
    this.#toggle.querySelector(".foreground").
      classList.
      replace("bg-green-ea", "bg-gray-200")
    this.#toggle.querySelector(".background").
      classList.
      replace("translate-x-5", "translate-x-0")

    this.#updateHiddenInput(null)
  }

  #check() {
    this.#toggle.ariaChecked = "true"
    this.#toggle.querySelector(".foreground").
      classList.
      replace("bg-gray-200", "bg-green-ea")
    this.#toggle.querySelector(".background").
      classList.
      replace("translate-x-0", "translate-x-5")

    this.#updateHiddenInput("Yes")
  }

  #updateHiddenInput(value) {
    this.#hiddenInput.value = value

    const event = new Event("change", { "bubbles": true })
    this.#hiddenInput.dispatchEvent(event)
  }
}

export class PageEvents {
  // Page load events
  static domLoaded() {
    return new Promise((resolve) => {
      if (document.readyState === "loading") {
        document.addEventListener('DOMContentLoaded', function() {
          resolve()
        })
      } else {
        resolve()
      }
    })
  }

  static pageLoaded() {
    return new Promise((resolve) => {
      if (document.readyState === "complete") {
        resolve()
      } else {
        document.onreadystatechange = function () {
          if (document.readyState === "complete") {
            resolve()
          }
        }
      }
    })
  }

  // Document events

  static #eventCallbacks = {}

  static event(eventType, callback) {
    this.#registerListener(eventType)
    PageEvents.#eventCallbacks[eventType].push(callback)
  }

  static #registerListener(eventType) {
    if (PageEvents.#eventCallbacks[eventType] !== undefined) return

    PageEvents.#eventCallbacks[eventType] = []
    document.addEventListener(eventType, this.onEvent.bind(this))
  }

  static onEvent(event) {
    const callbacks = this.#eventCallbacks[event.type] || []
    callbacks.forEach(callback => callback(event))
  }
}

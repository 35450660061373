// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application"

const controllers = import.meta.glob(["./**/*.js", "!./application.js"], { eager: true })
Object.entries(controllers).forEach(([path, controller]) => {
  const pathPaths = path.split("/").slice(1)
  pathPaths[pathPaths.length - 1] = pathPaths[pathPaths.length - 1].replace(".js", "")
  const controllerName = pathPaths.join("_")
  application.register(controllerName, controller.default)
})

// Refer to signature_pad Repo  https://github.com/szimek/signature_pad

import Animation from "../shared/animation"
import SignaturePad from "signature_pad"
import { PageEvents as on } from "../shared/page_events"
import { Turbo } from "@hotwired/turbo-rails"

class JobPad {
  static #singleton

  static initialise() {
    on.event("click", this.#onClick.bind(this))
    on.event("turbo:visit", this.#clearSingleton.bind(this))
    on.event("turbo:load", this.#checkForJobPad.bind(this))
  }

  static hasPad() {
    return this.#singleton !== null
  }

  static #clearSingleton() {
    this.#singleton = null
  }

  static #checkForJobPad() {
    const wrapper = document.getElementById("signature-pad")
    const previousSignatures = document.getElementById("previous-signatures")

    if (wrapper === null) {
      this.#singleton = null
    } else {
      this.#singleton = new JobPad(wrapper, previousSignatures)
    }
  }

  static #onClick(event) {
    if (!this.#singleton) return

    this.#singleton.onClick(event)
  }

  #canvas
  #clearBtn
  #hiddenInput
  #previousSignatures
  #saveButton
  #signaturePad
  #wrapper

  constructor(wrapper, previousSignatures) {
    this.#previousSignatures = previousSignatures
    this.#wrapper = wrapper
    this.#setState()
    this.#initialisePad()
  }

  onClick(event) {
    const target = event.target

    if (this.#wrapper.contains(target)) {
      this.#onPadClick(target)
    } else if (this.#previousSignatures?.contains(target)) {
      this.#onPreviousSignatureClick(target)
    }
  }

  #onPadClick(target) {
    if (target.tagName !== "BUTTON") return

    this.#specificButtonActions(target)
    this.#hasChanged()
  }

  #onPreviousSignatureClick(target) {
    const image = target.closest("img")
    if(!image) return

    this.#signaturePad.clear()
    this.#signaturePad.fromDataURL(image.src)
    this.#hasChanged(null, image.src)
  }

  #setState() {
    this.#canvas = this.#wrapper.querySelector("canvas")
    this.#clearBtn = this.#wrapper.querySelector("button[data-action=clear]")
    this.#hiddenInput = this.#wrapper.querySelector("input[type=hidden]")
    this.#signaturePad = new SignaturePad(this.#canvas)
    this.#saveButton = this.#wrapper.querySelector("button[data-action=save]")
  }

  #initialisePad() {
    this.#signaturePad.
      addEventListener("endStroke", this.#hasChanged.bind(this))
    this.#setCanvas()

    // On mobile devices it might make more sense to listen to orientation
    // change, rather than window resize events
    window.onresize = this.#resizeCanvas.bind(this)
    this.#resizeCanvas()
  }

  #hasChanged(_event, dataURL) {
    if (!dataURL && !this.#signaturePad.isEmpty()) {
      dataURL = this.#signaturePad.toDataURL()
    }

    this.#hiddenInput.value = dataURL
    this.#showSaveButton()
  }

  #showSaveButton() {
    Animation.showAndFade(this.#saveButton.parentNode)
  }

  #specificButtonActions(target) {
    switch(target) {
      case this.#clearBtn:
        this.#signaturePad.clear()
        break
      case this.#saveButton:
        this.#save()
        break
    }
  }

  #save() {
    const form = this.#wrapper.closest("form")
    Turbo.navigator.submitForm(form)
  }

  #setCanvas() {
    const dataURL = this.#hiddenInput.value
    this.#fromDataURL(dataURL)
  }

  #fromDataURL(dataURL) {
    if (dataURL) {
      this.#signaturePad.fromDataURL(dataURL)
    } else {
      this.#signaturePad.clear()
    }
  }

  // Adjust canvas coordinate space taking into account pixel ratio,
  // to make it look crisp on mobile devices.
  // This also causes canvas to be cleared
  #resizeCanvas() {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then
    const ratio =  Math.max(window.devicePixelRatio || 1, 1)

    // This part causes the canvas to be cleared
    this.#canvas.width = this.#canvas.offsetWidth * ratio
    this.#canvas.height = this.#canvas.offsetHeight * ratio
    this.#canvas.getContext("2d").scale(ratio, ratio)

    // This library does not listen for canvas changes, so after the canvas is
    // automatically cleared by the browser, SignaturePad#isEmpty might still
    // return false, even though the canvas looks empty, because the internal
    // data of this library wasn't cleared. To make sure that the state of this
    // library is consistent with visual state of the canvas, you have to clear
    // it manually
    this.#signaturePad.clear()
  }
}

on.domLoaded().then(() => JobPad.initialise())

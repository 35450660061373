import Animation from "../shared/animation"
import { PageEvents as on } from "../shared/page_events"

class HeaderNavigation {
  #profileButton
  #profileDropDown
  #profileNavStatus = "closed"

  constructor() {
    on.event("click", this.onClick.bind(this))
    on.event("turbo:load", this.#initialise.bind(this))
  }

  #initialise() {
    this.#profileButton = document.querySelector(".profile-button")
    this.#profileDropDown = document.querySelector(".profile-drop-down")
    if (!this.#profileButton || !this.#profileDropDown) return

    this.#setProfileNavStatus()
  }

  #setProfileNavStatus() {
    if (this.#profileDropDown.classList.contains("hidden")) {
      this.#profileNavStatus = "closed"
    } else {
      this.#profileNavStatus = "open"
    }
  }

  onClick(event) {
    if (!this.#profileButton || !this.#profileDropDown) return

    const target = event.target
    switch (this.#profileNavStatus) {
      case "closed":
        if (this.#clickedProfileButton(target)) {
          this.#openProfileNav()
        }
        break
      case "open":
        if (!this.#clickedProfileDropDown(target)) {
          this.#closeProfileNav()
        }
        break
    }
  }

  #clickedProfileButton(clickedNode) {
    return this.#profileButton.contains(clickedNode)
  }

  #clickedProfileDropDown(clickedNode) {
    return this.#profileDropDown.contains(clickedNode)
  }

  #openProfileNav() {
    this.#profileNavStatus = "opening"
    Animation.showAndFade(this.#profileDropDown).
      then(() => this.#profileNavStatus = "open")
  }

  #closeProfileNav() {
    this.#profileNavStatus = "closing"
    Animation.fadeAndHide(this.#profileDropDown).
      then(() => this.#profileNavStatus = "closed")
  }
}

new HeaderNavigation()
